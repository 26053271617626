<template>
  <div style="height: 100%;">
    <div class="closeWrelative">
      <v-btn @click="close" icon x-small>
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </div>
    <div class="recoverPasswordContainer" v-if="dialogPutCode === 0">
      <div class="d-flex justify-center flex-column mt-10">
        <p class="title_recover_password">Recuperar Contraseña</p>
        <p class="subtitle_recover_password">
          Ingresa tu número de teléfono para reestablecer tu contraseña
        </p>
      </div>
      <div class="d-flex justify-center flex-column">
        <div class="d-flex justify-center classContainerBtn">
          <div>
            <p class="p13">País (Prefijo)</p>
            <country-autocomplete @dialog:change="HandlerCountryResponse" />
          </div>
          <div class="ml-5 w-100">
            <p class="p13">Whastapp</p>
            <ValidationProvider
              name="telefono"
              rules="required|numeric"
              v-slot="{ errors }"
            >
              <v-text-field
                v-model="phone_number"
                class="mt-3 ml-auto"
                outlined
                label="Whastapp"
                :error-messages="errors"
              ></v-text-field>
            </ValidationProvider>
          </div>
        </div>
        <v-btn
          depressed
          class="white--text font-light py-5 text-capitalize mt-5"
          rounded
          color="#101820"
          :loading="loading"
          @click="HandlerGetCode"
        >
          Continuar
        </v-btn>
      </div>
    </div>
    <div class="recoverPasswordContainer_putCode" v-if="dialogPutCode === 1">
      <div class="d-flex justify-center flex-column mt-10">
        <p class="title_recover_password">Ingresa el código</p>
        <p class="subtitle_recover_password">
          Hemos enviado un código de verificación al número de teléfono que ingresaste.
        </p>
      </div>
      <ValidationProvider name="codigo" rules="required" v-slot="{ errors }">
        <v-text-field
          outlined
          label="Ingresar código"
          @keyup.enter="passes(HandlerConfrimCode)"
          v-model="code_recovery"
          :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <p class="classConYouReq_text">
        Podrás reenviar el código en {{ countGetCode }}s.
      </p>
      <v-btn
        depressed
        class="white--text font-light py-5 text-capitalize mt-5"
        rounded
        color="#101820"
        :loading="loading"
        @click="HandlerConfrimCode"
      >
        Continuar
      </v-btn>
      <v-btn
        :disabled="countGetCode > 0"
        depressed
        class="classBtnReqCode text-capitalize mt-5 py-5"
        rounded
        :loading="loading"
        @click="HandlerGetCode"
      >
        Reenviar Código
      </v-btn>
    </div>
    <div class="recoverPasswordContainer_putCode2" v-if="dialogPutCode === 2">
      <p class="title_recover_password">Ingresa el código</p>
      <p class="subtitle_recover_password">
        Hemos enviado un código de verificación al número de teléfono que ingresaste.
      </p>
      <p style="color: #929292">Nueva contraseña</p>
      <ValidationProvider
        name="contraseña"
        rules="required|confirmed:confirmation"
        v-slot="{ errors }"
      >
        <v-text-field
          type="password"
          v-model="password"
          :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <p style="color: #929292">Confirmar Contraseña</p>
      <ValidationProvider
        name="confirmar contraseña"
        rules="required"
        vid="confirmation"
        v-slot="{ errors }"
      >
        <v-text-field
          @keyup.enter="passes(HandlerChangePassword)"
          type="password"
          v-model="confirm_password"
          :error-messages="errors"
        ></v-text-field>
      </ValidationProvider>
      <v-btn
        :loading="loadingReset"
        @click="HandlerChangePassword"
        color="#101820"
        rounded
        class="white--text font-light py-5 text-capitalize mt-5"
      >
        Cambiar contraseña
      </v-btn>
    </div>
  </div>
</template>

<script>
import CountryComponent from "@/components/Utils/country_autocomplete";
export default {
  props: {
    active: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  components: {
    "country-autocomplete": CountryComponent,
  },
  data() {
    return {
      isNotAccept: true,
      loadingRecovery: false,
      dialogPutCode: 0,
      phone_number: "",
      loadingCode: false,
      countGetCode: 0,
      intervalId: null,
      loadingReset: false,
      countries: [],
      country_id: "",
      phone: "",
      canChange: false,
      code_recovery: "",
      canReset: false,
      password: "",
      confirm_password: "",
      subdomain: "",
    };
  },
  watch: {},
  created() {
    this.HandlerCountry();
    this.subdomain = window.location.hostname.split('.')[0];
  },
  methods: {
    close() {
      this.$emit("dialog:change", false);
    },
    async HandlerCountry() {
      try {
        const response = await this.$store.dispatch("auth/COUNTRIES");
        this.country_id = response.data.data.find((val) => val.id == 238);
        this.countries = response.data.data;
      } catch (error) {
        console.log("error", error.response);
      }
    },
    async HandlerGetCode() {
      try {
        this.loadingRecovery = true;
        const request = {
          subdomain: this.subdomain,
          country_id: this.country_id.id,
          phone_number: this.phone_number,
        };

        await this.$store.dispatch("auth/RECOVERY_CODE", request);

        this.$snotify.success(
          "Tu codigo ha sido enviado con exitos",
          "¡Exitos!"
        );
        this.dialogPutCode = 1;
        this.isNotAccept = false;
        this.countGetCode = 60;
        this.intervalId = this.intervalId = setInterval(() => {
          if (this.countGetCode > 0) {
            this.countGetCode--;
          } else {
            clearInterval(this.intervalId);
          }
        }, 1000);
      } catch (error) {
        console.log("error", error.response);
        this.$snotify.error("Ha ocurrido un error con tu solicitud", "¡Error!");
      } finally {
        this.loadingRecovery = false;
      }
    },
    async HandlerConfrimCode() {
      try {
        this.loadingCode = true;
        const response = await this.$store.dispatch("auth/CONFIRM_CODE", {
          code: this.code_recovery,
        });
        console.log( response.data, " response.data", response.data.message == "Este token de restablecimiento de contraseña es válido." );
        if (
          response.data.message ==
          "Este token de restablecimiento de contraseña es válido."
        ) {
          this.dialogPutCode = 2;
          this.canReset = true;
        }

        this.$snotify.success(
          "Su codigo ha sido confirmado con exitos, puede cambiar su contraseña",
          "¡Exitos!"
        );
      } catch (error) {
        console.log("error", error.response);
        this.$snotify.error("Ha ocurrido un error con tu solicitud", "¡Error!");
      } finally {
        this.loadingCode = false;
      }
    },
    async HandlerChangePassword() {
      try {
        this.loadingReset = true;
        const request = {
          country_id: this.country_id.id,
          phone_number: this.phone_number,
          password: this.password,
          password_confirmation: this.confirm_password,
          token: this.code_recovery,
        };
        await this.$store.dispatch("auth/RESET_PASSWORD", request);
        this.$snotify.success(
          "Su contraseña ha sido cambiada con exitos",
          "¡Exitos!"
        );
        // this.$router.push({ name: "Login" });
        this.$emit("dialog:change", false);
      } catch (error) {
        console.log(error.response);
        this.$snotify.error("Ha ocurrido un error con tu solicitud", "¡Error!");
      } finally {
        this.loadingReset = false;
      }
    },
    HandlerCountryResponse(val) {
      this.country_id = val;
    },
  },
};
</script>

<style>
.recoverPasswordContainer_putCode2 {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.classBtnReqCode {
  color: white;
  border-radius: 32px;
  text-align: center;
  text-transform: capitalize;
  font-family: "Poppins";
  font: normal normal normal 15px/30px Poppins;
  letter-spacing: 0px;
  color: #101820;
}
.classConYouReq_text {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #0076ff;
}
.recoverPasswordContainer_putCode {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.closeWrelative {
  position: absolute;
  top: 0;
  margin: 3rem;
  right: 0;
}
.classContainerBtn {
  margin-bottom: 10rem;
}
.recoverPasswordContainer {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.subtitle_recover_password {
  text-align: center;
  font-family: "Poppins";
  font: normal normal 300 13px/20px Poppins;
  letter-spacing: 0px;
  color: #6f7b87;
  margin-bottom: 4rem !important;
}
.title_recover_password {
  font-family: "Poppins", serif;
  font-size: 28px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  text-align: center;
}
.custom-color {
  color: #929292;
}
</style>
